import { default as indexvGgAHIagnXMeta } from "/app/pages/[companyname]/index.vue?macro=true";
import { default as _91encodedemail_93BUmugMjoZ5Meta } from "/app/pages/[companyname]/rsvp/[id]/[encodedemail].vue?macro=true";
import { default as backup_45_91encodedemail_93_45with_45adult_45childjQNl0xSx3WMeta } from "/app/pages/[companyname]/rsvp/[id]/backup-[encodedemail]-with-adult-child.vue?macro=true";
import { default as index0kcSDBwDTQMeta } from "/app/pages/announcements/index.vue?macro=true";
import { default as indexhg2nWJrbRRMeta } from "/app/pages/dashboard/index.vue?macro=true";
import { default as dashboardewT5qYF5VoMeta } from "/app/pages/database/dashboard.vue?macro=true";
import { default as _91id_93CCusQpLdUTMeta } from "/app/pages/database/records/[id].vue?macro=true";
import { default as listxOT7CjwOPlMeta } from "/app/pages/database/records/list.vue?macro=true";
import { default as oldIdxG5v9YsWUnMeta } from "/app/pages/database/records/oldId.vue?macro=true";
import { default as event_45attendance_45scannerTvGNJEImW2Meta } from "/app/pages/event-attendance-scanner.vue?macro=true";
import { default as event_45attendanceNhjDDbXA2GMeta } from "/app/pages/event-attendance.vue?macro=true";
import { default as indexnqGPcl8Ho6Meta } from "/app/pages/events/[id]/index.vue?macro=true";
import { default as ticketsDqxvdTlLRRMeta } from "/app/pages/events/[id]/tickets.vue?macro=true";
import { default as add_45updateZJGTiblPOjMeta } from "/app/pages/events/add-update.vue?macro=true";
import { default as indexWgIHVzns1AMeta } from "/app/pages/events/index.vue?macro=true";
import { default as indexpcgO0qLbvhMeta } from "/app/pages/families/index.vue?macro=true";
import { default as approvalucIxuLBxz6Meta } from "/app/pages/feed/[clubid]/approval.vue?macro=true";
import { default as contest_45adminrXQi67PuqHMeta } from "/app/pages/feed/[clubid]/contest-admin.vue?macro=true";
import { default as photo_45galleryuGPeVwTCTCMeta } from "/app/pages/feed/[clubid]/photo-gallery.vue?macro=true";
import { default as qr48ON1TpSNTMeta } from "/app/pages/feed/[clubid]/qr.vue?macro=true";
import { default as indexYhIv6bWPrQMeta } from "/app/pages/feed/[companycode]/[clubid]/index.vue?macro=true";
import { default as new_45contestFcoUuxO3H9Meta } from "/app/pages/feed/[companycode]/[clubid]/new-contest.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexjq7Ho9BMUYMeta } from "/app/pages/invoices/index.vue?macro=true";
import { default as ms_45redirect_45herepBR5balpmzMeta } from "/app/pages/ms-redirect-here.vue?macro=true";
import { default as change_45passwordVj4R8BjKaXMeta } from "/app/pages/my-profile/change-password.vue?macro=true";
import { default as indexbIcMYOjlfRMeta } from "/app/pages/my-profile/index.vue?macro=true";
import { default as newpageYRYNpL8DSSMeta } from "/app/pages/newpage.vue?macro=true";
import { default as payment_45successiL8KAe8rlNMeta } from "/app/pages/no-auth/text-messaging/payment-success.vue?macro=true";
import { default as plansgxLq0bCpm3Meta } from "/app/pages/no-auth/text-messaging/plans.vue?macro=true";
import { default as ParentZ6ZqOwRvvZMeta } from "/app/pages/Parent.vue?macro=true";
import { default as indexovVtp4PBUXMeta } from "/app/pages/participants/[id]/index.vue?macro=true";
import { default as allHFDNPBw4rLMeta } from "/app/pages/participants/all.vue?macro=true";
import { default as history_45for_45program2J5bbDiFkpMeta } from "/app/pages/payments/history-for-program.vue?macro=true";
import { default as historyabBw7azUgaMeta } from "/app/pages/payments/history.vue?macro=true";
import { default as transaction3kvvK3OPPhMeta } from "/app/pages/program/[clubId]/transaction.vue?macro=true";
import { default as indexu9QcDESI3cMeta } from "/app/pages/program/index.vue?macro=true";
import { default as index3wV7fz535qMeta } from "/app/pages/programs/index.vue?macro=true";
import { default as indexEAzWAcRdfRMeta } from "/app/pages/rsvp/[id]/index.vue?macro=true";
import { default as index3PcRRShzyvMeta } from "/app/pages/rsvp/index.vue?macro=true";
import { default as settingsLwEYOlkQxrMeta } from "/app/pages/settings.vue?macro=true";
import { default as indexyNQEpNx7yEMeta } from "/app/pages/students/index.vue?macro=true";
import { default as payment_45successrUBkSkXmASMeta } from "/app/pages/text-messaging/payment-success.vue?macro=true";
import { default as plansWfo8Jh1TCwMeta } from "/app/pages/text-messaging/plans.vue?macro=true";
import { default as usage_45historyxqHZ1EZbn3Meta } from "/app/pages/text-messaging/usage-history.vue?macro=true";
import { default as indexL9SrZLs43zMeta } from "/app/pages/transaction/index.vue?macro=true";
import { default as unauthorizedUnTSWnoGh4Meta } from "/app/pages/unauthorized.vue?macro=true";
import { default as indexnq54o81oozMeta } from "/app/pages/users/index.vue?macro=true";
export default [
  {
    name: "companyname",
    path: "/:companyname()",
    meta: indexvGgAHIagnXMeta || {},
    component: () => import("/app/pages/[companyname]/index.vue").then(m => m.default || m)
  },
  {
    name: "companyname-rsvp-id-encodedemail",
    path: "/:companyname()/rsvp/:id()/:encodedemail()",
    meta: _91encodedemail_93BUmugMjoZ5Meta || {},
    component: () => import("/app/pages/[companyname]/rsvp/[id]/[encodedemail].vue").then(m => m.default || m)
  },
  {
    name: "companyname-rsvp-id-backup-encodedemail-with-adult-child",
    path: "/:companyname()/rsvp/:id()/backup-:encodedemail()-with-adult-child",
    meta: backup_45_91encodedemail_93_45with_45adult_45childjQNl0xSx3WMeta || {},
    component: () => import("/app/pages/[companyname]/rsvp/[id]/backup-[encodedemail]-with-adult-child.vue").then(m => m.default || m)
  },
  {
    name: "announcements",
    path: "/announcements",
    meta: index0kcSDBwDTQMeta || {},
    component: () => import("/app/pages/announcements/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexhg2nWJrbRRMeta || {},
    component: () => import("/app/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "database-dashboard",
    path: "/database/dashboard",
    meta: dashboardewT5qYF5VoMeta || {},
    component: () => import("/app/pages/database/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "database-records-id",
    path: "/database/records/:id()",
    meta: _91id_93CCusQpLdUTMeta || {},
    component: () => import("/app/pages/database/records/[id].vue").then(m => m.default || m)
  },
  {
    name: "database-records-list",
    path: "/database/records/list",
    meta: listxOT7CjwOPlMeta || {},
    component: () => import("/app/pages/database/records/list.vue").then(m => m.default || m)
  },
  {
    name: "database-records-oldId",
    path: "/database/records/oldId",
    meta: oldIdxG5v9YsWUnMeta || {},
    component: () => import("/app/pages/database/records/oldId.vue").then(m => m.default || m)
  },
  {
    name: "event-attendance-scanner",
    path: "/event-attendance-scanner",
    component: () => import("/app/pages/event-attendance-scanner.vue").then(m => m.default || m)
  },
  {
    name: "event-attendance",
    path: "/event-attendance",
    meta: event_45attendanceNhjDDbXA2GMeta || {},
    component: () => import("/app/pages/event-attendance.vue").then(m => m.default || m)
  },
  {
    name: "events-id",
    path: "/events/:id()",
    meta: indexnqGPcl8Ho6Meta || {},
    component: () => import("/app/pages/events/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "events-id-tickets",
    path: "/events/:id()/tickets",
    meta: ticketsDqxvdTlLRRMeta || {},
    component: () => import("/app/pages/events/[id]/tickets.vue").then(m => m.default || m)
  },
  {
    name: "events-add-update",
    path: "/events/add-update",
    meta: add_45updateZJGTiblPOjMeta || {},
    component: () => import("/app/pages/events/add-update.vue").then(m => m.default || m)
  },
  {
    name: "events",
    path: "/events",
    meta: indexWgIHVzns1AMeta || {},
    component: () => import("/app/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "families",
    path: "/families",
    meta: indexpcgO0qLbvhMeta || {},
    component: () => import("/app/pages/families/index.vue").then(m => m.default || m)
  },
  {
    name: "feed-clubid-approval",
    path: "/feed/:clubid()/approval",
    meta: approvalucIxuLBxz6Meta || {},
    component: () => import("/app/pages/feed/[clubid]/approval.vue").then(m => m.default || m)
  },
  {
    name: "feed-clubid-contest-admin",
    path: "/feed/:clubid()/contest-admin",
    meta: contest_45adminrXQi67PuqHMeta || {},
    component: () => import("/app/pages/feed/[clubid]/contest-admin.vue").then(m => m.default || m)
  },
  {
    name: "feed-clubid-photo-gallery",
    path: "/feed/:clubid()/photo-gallery",
    meta: photo_45galleryuGPeVwTCTCMeta || {},
    component: () => import("/app/pages/feed/[clubid]/photo-gallery.vue").then(m => m.default || m)
  },
  {
    name: "feed-clubid-qr",
    path: "/feed/:clubid()/qr",
    meta: qr48ON1TpSNTMeta || {},
    component: () => import("/app/pages/feed/[clubid]/qr.vue").then(m => m.default || m)
  },
  {
    name: "feed-companycode-clubid",
    path: "/feed/:companycode()/:clubid()",
    meta: indexYhIv6bWPrQMeta || {},
    component: () => import("/app/pages/feed/[companycode]/[clubid]/index.vue").then(m => m.default || m)
  },
  {
    name: "feed-companycode-clubid-new-contest",
    path: "/feed/:companycode()/:clubid()/new-contest",
    meta: new_45contestFcoUuxO3H9Meta || {},
    component: () => import("/app/pages/feed/[companycode]/[clubid]/new-contest.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices",
    path: "/invoices",
    meta: indexjq7Ho9BMUYMeta || {},
    component: () => import("/app/pages/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "ms-redirect-here",
    path: "/ms-redirect-here",
    component: () => import("/app/pages/ms-redirect-here.vue").then(m => m.default || m)
  },
  {
    name: "my-profile-change-password",
    path: "/my-profile/change-password",
    meta: change_45passwordVj4R8BjKaXMeta || {},
    component: () => import("/app/pages/my-profile/change-password.vue").then(m => m.default || m)
  },
  {
    name: "my-profile",
    path: "/my-profile",
    meta: indexbIcMYOjlfRMeta || {},
    component: () => import("/app/pages/my-profile/index.vue").then(m => m.default || m)
  },
  {
    name: "newpage",
    path: "/newpage",
    component: () => import("/app/pages/newpage.vue").then(m => m.default || m)
  },
  {
    name: "no-auth-text-messaging-payment-success",
    path: "/no-auth/text-messaging/payment-success",
    meta: payment_45successiL8KAe8rlNMeta || {},
    component: () => import("/app/pages/no-auth/text-messaging/payment-success.vue").then(m => m.default || m)
  },
  {
    name: "no-auth-text-messaging-plans",
    path: "/no-auth/text-messaging/plans",
    component: () => import("/app/pages/no-auth/text-messaging/plans.vue").then(m => m.default || m)
  },
  {
    name: "Parent",
    path: "/Parent",
    meta: ParentZ6ZqOwRvvZMeta || {},
    component: () => import("/app/pages/Parent.vue").then(m => m.default || m)
  },
  {
    name: "participants-id",
    path: "/participants/:id()",
    meta: indexovVtp4PBUXMeta || {},
    component: () => import("/app/pages/participants/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "participants-all",
    path: "/participants/all",
    meta: allHFDNPBw4rLMeta || {},
    component: () => import("/app/pages/participants/all.vue").then(m => m.default || m)
  },
  {
    name: "payments-history-for-program",
    path: "/payments/history-for-program",
    meta: history_45for_45program2J5bbDiFkpMeta || {},
    component: () => import("/app/pages/payments/history-for-program.vue").then(m => m.default || m)
  },
  {
    name: "payments-history",
    path: "/payments/history",
    meta: historyabBw7azUgaMeta || {},
    component: () => import("/app/pages/payments/history.vue").then(m => m.default || m)
  },
  {
    name: "program-clubId-transaction",
    path: "/program/:clubId()/transaction",
    meta: transaction3kvvK3OPPhMeta || {},
    component: () => import("/app/pages/program/[clubId]/transaction.vue").then(m => m.default || m)
  },
  {
    name: "program",
    path: "/program",
    meta: indexu9QcDESI3cMeta || {},
    component: () => import("/app/pages/program/index.vue").then(m => m.default || m)
  },
  {
    name: "programs",
    path: "/programs",
    meta: index3wV7fz535qMeta || {},
    component: () => import("/app/pages/programs/index.vue").then(m => m.default || m)
  },
  {
    name: "rsvp-id",
    path: "/rsvp/:id()",
    meta: indexEAzWAcRdfRMeta || {},
    component: () => import("/app/pages/rsvp/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "rsvp",
    path: "/rsvp",
    meta: index3PcRRShzyvMeta || {},
    component: () => import("/app/pages/rsvp/index.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsLwEYOlkQxrMeta || {},
    component: () => import("/app/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "students",
    path: "/students",
    meta: indexyNQEpNx7yEMeta || {},
    component: () => import("/app/pages/students/index.vue").then(m => m.default || m)
  },
  {
    name: "text-messaging-payment-success",
    path: "/text-messaging/payment-success",
    meta: payment_45successrUBkSkXmASMeta || {},
    component: () => import("/app/pages/text-messaging/payment-success.vue").then(m => m.default || m)
  },
  {
    name: "text-messaging-plans",
    path: "/text-messaging/plans",
    meta: plansWfo8Jh1TCwMeta || {},
    component: () => import("/app/pages/text-messaging/plans.vue").then(m => m.default || m)
  },
  {
    name: "text-messaging-usage-history",
    path: "/text-messaging/usage-history",
    meta: usage_45historyxqHZ1EZbn3Meta || {},
    component: () => import("/app/pages/text-messaging/usage-history.vue").then(m => m.default || m)
  },
  {
    name: "transaction",
    path: "/transaction",
    meta: indexL9SrZLs43zMeta || {},
    component: () => import("/app/pages/transaction/index.vue").then(m => m.default || m)
  },
  {
    name: "unauthorized",
    path: "/unauthorized",
    component: () => import("/app/pages/unauthorized.vue").then(m => m.default || m)
  },
  {
    name: "users",
    path: "/users",
    meta: indexnq54o81oozMeta || {},
    component: () => import("/app/pages/users/index.vue").then(m => m.default || m)
  }
]