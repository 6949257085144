<template>
  <NuxtLayout>
    <NuxtPage/>
  </NuxtLayout>
</template>

<script setup lang="ts">


useHead({
  title: "Aphelia",
  meta: [
    {
      name: 'description',
      content: 'Universal classroom attendance management platform for educators and families.'
    }
  ],
  script: [{
    async: true,
    src: "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
  }]
});
</script>