import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_t2GMTTFnMT from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import paypal_NOpaFx1I2O from "/app/node_modules/nuxt-paypal/dist/runtime/plugins/paypal.mjs";
import templates_pwa_client_8b84a6f0_IIk9nIomk5 from "/app/.nuxt/templates.pwa.client.8b84a6f0.ts";
import plugin_eskviOYyLt from "/app/node_modules/nuxt-vue3-google-signin/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import msal_YjX35aVrLC from "/app/plugins/msal.ts";
import analytics_client_mtqVw3YRXk from "/app/plugins/analytics.client.ts";
import apexcharts_client_Dd5Ebxj7XB from "/app/plugins/apexcharts.client.ts";
import appleSignin_T7OY260eRY from "/app/plugins/appleSignin.ts";
import datatable_ITrvLviDYF from "/app/plugins/datatable.ts";
import mommentjs_3h5nqGQT1y from "/app/plugins/mommentjs.ts";
import qrCodeVue3_client_d0XiBbYnlt from "/app/plugins/qrCodeVue3.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_t2GMTTFnMT,
  paypal_NOpaFx1I2O,
  templates_pwa_client_8b84a6f0_IIk9nIomk5,
  plugin_eskviOYyLt,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  msal_YjX35aVrLC,
  analytics_client_mtqVw3YRXk,
  apexcharts_client_Dd5Ebxj7XB,
  appleSignin_T7OY260eRY,
  datatable_ITrvLviDYF,
  mommentjs_3h5nqGQT1y,
  qrCodeVue3_client_d0XiBbYnlt
]